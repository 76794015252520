
import * as Sentry from '@sentry/vue'
import { Component, Vue } from 'vue-property-decorator'
import BISListForm from '@/components/bis_list/form.vue'
import CharacterBio from '@/components/character_bio.vue'
import CharacterForm from '@/components/character_form.vue'
import BISListModify from '@/dataclasses/bis_list_modify'
import { Character } from '@/interfaces/character'
import Team from '@/interfaces/team'
import {
  BISListErrors,
  ProxyCreateErrors,
} from '@/interfaces/responses'
import TeamViewMixin from '@/mixins/team_view_mixin'

@Component({
  components: {
    BISListForm,
    CharacterBio,
    CharacterForm,
  },
})
export default class NewProxy extends TeamViewMixin {
  bis = new BISListModify()

  bisApiErrors: BISListErrors = {}

  character: Character | null = null

  // This just comes from response.lodestone_id
  characterApiErrors: string[] = []

  loading = true

  requesting = false

  team!: Team

  get readUrl(): string {
    return `/backend/api/team/${this.teamId}/`
  }

  get writeUrl(): string {
    return `/backend/api/team/${this.teamId}/proxies/`
  }

  changeCharacter(): void {
    this.character = null
    this.characterApiErrors = []
  }

  checkPermissions(): void {
    // Ensure that the person on this page is the team leader and not anybody else
    if (!this.userHasProxyManagerPermission) {
      this.$router.push(`/team/${this.team.id}/`, () => {
        Vue.notify({ text: 'You do not have permission to manage Proxy Characters.', type: 'is-warning' })
      })
    }
  }

  created(): void {
    this.fetchTeam(false)
  }

  updateChar(char: Character): void {
    this.character = char
  }

  // API Interaction
  async createProxy(): Promise<void> {
    // Don't allow multiple requests
    if (this.character === null || this.requesting) return
    this.requesting = true

    const body = JSON.stringify({ character: this.character, bis: this.bis })
    try {
      const response = await fetch(this.writeUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': Vue.$cookies.get('csrftoken'),
        },
        body,
      })

      if (response.ok) {
        // Redirect back to Team Details
        this.$store.dispatch('fetchCharacters')
        this.$router.push(`/team/${this.team.id}/management/`, () => {
          Vue.notify({ text: `New Proxy Character created successfully!`, type: 'is-success' })
        })
      }
      else {
        super.handleError(response.status)
        const json = await response.json() as ProxyCreateErrors
        if (json.character.lodestone_id != null) {
          this.characterApiErrors = json.character.lodestone_id
        }
        this.bisApiErrors = json.bis
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to create proxy Character.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
    finally {
      this.requesting = false
    }
  }

  async fetchTeam(reload: boolean): Promise<void> {
    // Load the team data from the API
    try {
      const response = await fetch(this.readUrl)
      if (response.ok) {
        // Parse the JSON into a team and save it
        this.team = (await response.json()) as Team
        this.checkPermissions()
        this.loading = false
        if (reload) this.$forceUpdate()
        document.title = `New Proxy - ${this.team.name} - Savage Aim`
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Team.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async load(): Promise<void> {
    this.fetchTeam(true)
  }
}
